import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as CookieConsentActions from './cookie-consent.actions';
import * as CookieConsentSelectors from './cookie-consent.selectors';

@Injectable()
export class CookieConsentFacade {
  accepted$ = this.store.pipe(select(CookieConsentSelectors.getAcceptanceStatus));

  constructor(private readonly store: Store) {}

  change(status: boolean) {
    this.store.dispatch(CookieConsentActions.changed({ status }));
  }

  reset() {
    this.store.dispatch(CookieConsentActions.reset());
  }
}
