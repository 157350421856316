import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { COOKIEHANDLING_CONFIG, CookiehandlingConfigInterface } from '../config/cookiehandling-config.interface';
import { CookiehandlingServiceInterface } from '../interface/cookie-handling.interface';

@Injectable({
  providedIn: 'root',
})
export class CookiehandlingService implements CookiehandlingServiceInterface {
  private domain?: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(COOKIEHANDLING_CONFIG) private config: CookiehandlingConfigInterface
  ) {}

  getDomain(): string | undefined {
    return this.domain;
  }

  setDomain(domain: string) {
    this.domain = domain;
  }

  read(name: string): string {
    const rawcookies = this.document.cookie.split('; ');
    const cookieEntry = rawcookies.map((cookieentry) => cookieentry.split('=')).find((entry) => entry[0] === name);

    return Array.isArray(cookieEntry) ? cookieEntry[1] : '';
  }

  public write(name: string, value: string, expires?: Date, domain?: string, path?: string) {
    /* istanbul ignore else  */
    if (this.config && this.config.production) {
      const cookiedomain = domain ?? this.domain ?? this.config.defaultDomain;
      const isLocalhost = cookiedomain.includes('localhost');

      this.document.cookie =
        `${name}=${value ? value : ''}${cookiedomain && !isLocalhost ? ';secure;domain=' + cookiedomain : ''}` +
        `;path=${path ? path : '/'}${expires ? ';expires=' + expires.toUTCString() : ''}`;
    } else {
      this.document.cookie =
        `${name}=${value ? value : ''}${domain ? ';domain=' + domain : ''};path=${path ? path : '/'}` +
        `${expires ? ';expires=' + expires.toUTCString() : ''}`;
    }
  }
}
