import { Action, createReducer, on } from '@ngrx/store';

import * as CookieConsentActions from './cookie-consent.actions';

export const COOKIE_CONSENT_FEATURE_KEY = 'cookieConsent';

export interface CookieConsentState {
  accepted?: boolean;
}

export interface CookieConsentPartialState {
  readonly [COOKIE_CONSENT_FEATURE_KEY]: CookieConsentState;
}

export const initialCookieConsentState: CookieConsentState = {};

const reducer = createReducer(
  initialCookieConsentState,
  on(CookieConsentActions.initialize, (state, action) => ({ ...state, accepted: action.status })),
  on(CookieConsentActions.changed, (state, action) => ({ ...state, accepted: action.status })),
  on(CookieConsentActions.reset, (state) => ({ ...state, accepted: undefined }))
);

export function cookieConsentReducer(state: CookieConsentState | undefined, action: Action) {
  return reducer(state, action);
}
