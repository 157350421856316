import { CommonModule, isPlatformServer } from '@angular/common';
import { Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { Store, StoreModule } from '@ngrx/store';
import { CookiehandlingService } from '@planone/shared/util-cookie-handling';

import * as CookieConsentActions from './state/cookie-consent.actions';
import { CookieConsentFacade } from './state/cookie-consent.facade';
import * as fromCookieConsent from './state/cookie-consent.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromCookieConsent.COOKIE_CONSENT_FEATURE_KEY, fromCookieConsent.cookieConsentReducer),
  ],
  providers: [CookieConsentFacade],
})
export class SharedFeatureCookieConsentModule {
  constructor(
    private cookieHandlingService: CookiehandlingService,
    private store: Store,
    @Inject(PLATFORM_ID) private platform: string
  ) {
    if (isPlatformServer(this.platform)) {
      this.store.dispatch(CookieConsentActions.initialize({ status: undefined }));
    } else {
      const cookie = this.cookieHandlingService.read('CookieConsent');
      const status = cookie !== '' ? cookie === 'allow' : undefined;
      this.store.dispatch(CookieConsentActions.initialize({ status }));
    }
  }
}
